<template>
  <!-- Error page-->
  <b-card>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="text-left">
          <h2 class="mb-1">
            Hmmmm... this page was not found 🕵🏻‍♀️
          </h2>
          <p class="text-max-width">
            Maybe this page doesn't exist or there was an error with the URL.
            If you think this is a problem then please
            <a
              href="#"
            >contact us so we can fix it.</a>
          </p>
          <b-card-img
            :src="imgUrl"
            fluid
            class="error-img"
          />
        </div>
      </div>
    </div>
  </b-card>
  <!-- / Error page-->
</template>

<script>
import { BCard, BCardImg } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardImg,
  },
  data() {
    return {
      imgUrl: '',
    }
  },
}
</script>

<style lang="scss" scoped>
.error-img {
  border-radius: 1rem;
  max-width: 520px;
}
.text-max-width {
  max-width: 520px;
}
</style>
